<template>
  <div class="image_upload">
    <el-upload
      class="avatar-uploader"
      action=""
      :http-request="upload"
      :show-file-list="false"
      accept="image/*"
    >
      <img v-if="imageShow" :src="imageShow" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <div v-if="addTitleShow" class="addinfo_title">推荐尺寸：750*230px</div>
  </div>
</template>

<script>
import { uploadAdminOne } from '@/api/common.js'
export default {
  name: 'UploadImahesOne',
  props: {
    image: {
      type: String
    },
    imageShow: {
      type: String
    },
    addTitleShow: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    //上传图片
    async upload(params) {
      const form = new FormData()
      form.append('file', params.file)
      const { data: res } = await uploadAdminOne(form)
      if (res.resultCode === 200) {
        this.$emit('updateImgOne', res.data)
        this.$emit('uploadOneImgShow', res.data)
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .v-modal{
  background: #fff !important;
}
.image_upload {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    border: 1px dashed #eee;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }
  .addinfo_title {
    font-size: 14px;
    color: #999;
  }
}
</style>