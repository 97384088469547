<template>
  <div class="informationList">
    <el-card style="margin: 16px 22px; border-radius: 12px">
      <div>
        <span class="information-tip">{{ type ? '编辑名帖' : '新增名帖' }}</span>
        <div class="information-line"></div>
      </div>

      <el-form
        :model="paramForm"
        :rules="formRules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="名帖名称" prop="information" class="information-name">
          <el-input
            style="width: 450px"
            v-model="paramForm.information"
            placeholder="请输入名帖名称"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="关联会员" prop="userId" class="information-name">
          <el-select
            v-model="paramForm.userId"
            filterable
            placeholder="请选择"
            @change="changeSelect"
          >
            <el-option
              v-for="item in ListInformationAndCustomerResultsVal"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封面 " prop="cover">
          <div style="color: #7c71e1; font-size: 12px; overflow: hidden">
            <upload-image-one
              :image="paramForm.cover"
              :imageShow="paramForm.cover"
              :addTitleShow="false"
              @updateImgOne="updateImgOne"
              class="image"
            />
          </div>
        </el-form-item>

        <el-form-item label="名帖描述" prop="informationDescription">
          <div class="editBar">
            <editorbar
              @change="editorChange"
              v-model="paramForm.informationDescription"
              :isClear="isClear"
            />
          </div>
          <div class="bottom_tip" style="color: #7c71e1; font-size: 12px; width: 868px">
            (图片的建议尺寸长为750px，富文本内图片格式应为jpg、jpeg、png，图片应小于12MB)
          </div>
        </el-form-item>

        <el-form-item label="关键词" class="information-name">
          <el-input
            type="textarea"
            v-model="paramForm.keywords"
            placeholder="请输入内容"
            class="keyword"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="标签" prop="labels" class="information-name">
          <div class="remove_label">
            <div class="lebel_item" v-for="(item, index) in paramForm.labels" :key="index">
              <span>{{ item }}</span>
              <i class="el-icon-close remove_icon" @click="clickRemovLabel(index)"></i>
            </div>
            <div>
              <el-input
                class="add_label"
                v-model="addLebal"
                @keyup.enter.native="clickAddLabel"
              ></el-input>
              <div class="add_box" @click="clickAddLabel">
                <i class="el-icon-circle-plus-outline color add_icon remove_icon"></i>
                <span class="color">添加</span>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>

      <div class="bottomButton">
        <el-button
          type="primary"
          style="width: 136px; background-color: #7c71e1 !important"
          @click="save"
          >保存</el-button
        >
        <!-- <el-button style="width: 136px; border: 1px solid #7c71e1" @click="cancel" plain
          >取消</el-button
        > -->
        <el-popconfirm
          confirm-button-text="保存"
          cancel-button-text="取消"
          icon="el-icon-info"
          icon-color="red"
          title="您还未保存，是否先保存再退出？"
          :hide-icon="true"
          @confirm="save"
          @cancel="clickCancel"
          class="popconfirm"
        >
          <el-button
            style="width: 136px; border: 1px solid #7c71e1; margin-left: 30px"
            plain
            slot="reference"
            >取消</el-button
          >
        </el-popconfirm>
      </div>
    </el-card>
  </div>
</template>
<script>
import {
  detailList,
  getUpdateList,
  selectParticulars,
  selectCustomerList,
  selectNameCard,
  updateNameCard
} from '../../../api/information'
import { saveNameCard } from '../../../api/userManagement'
import UploadImageOne from '../../../components/UploadImageOne.vue'
import Editorbar from '../../../components/Editorbar'

export default {
  name: 'informationList',
  components: {
    UploadImageOne,
    Editorbar
  },
  data() {
    return {
      CheckTrue: true,
      CheckFalse: false,
      paramForm: {
        cover: '',
        createTime: '',
        headPortrait: '',
        id: 0,
        information: '',
        informationDescription: '',
        isIssue: 0,
        issueTime: '',
        keywords: '',
        label: '',
        labelTwo: '',
        labels: [],
        labelsTwo: [],
        personalIntro: '',
        readNumber: 0,
        time: '',
        userId: '',
        userName: '',
        whetherMember: 0
      },

      clientAlias1: '',
      clientAlias: [],
      formRules: {
        information: [{ required: true, message: '请输入名帖名称', trigger: 'blur' }],
        cover: [{ required: true, message: '请上传资讯封面', trigger: 'change' }],
        informationDescription: [{ required: true, message: '请输入名帖描述', trigger: 'blur' }]
      },
      options: [],
      inputVisible: false,
      dynamicTags: [],
      isClear: false,
      chargeWay: '',
      memberList: {},
      labels: [],
      addLebal: '',

      // 关联会员数据
      ListInformationAndCustomerResultsVal: []
    }
  },
  filters: {
    valueId(val) {
      // console.log(val)
      return val
    }
  },
  computed: {
    id() {
      // console.log(this.$route.query.id)
      return this.$route.query.id * 1
    },
    type() {
      return this.$route.query.informationType * 1
    }
  },
  async created() {
    if (this.type) {
      // console.log(this.id)
      let { data: res } = await selectNameCard({ id: this.id })
      console.log(res)
      console.log(this.paramForm)
      this.paramForm = Object.assign(this.paramForm, res.data)
      // this.paramForm.label = this.paramForm.label.split(',')
    }
    this.getListInformationAndCustomerResults()
  },

  methods: {
    save() {
      // console.log(this.paramForm.correctionAccessorys)
      // console.log(this.paramForm)
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.type) {
            // 编辑
            console.log(this.paramForm)
            const { data: res } = await updateNameCard(this.paramForm)
            if (res.resultCode === 200) {
              this.$message.success('编辑成功！')
              this.$router.go(-1)
            } else {
              this.$message.success('编辑失败，请稍候重试')
            }
          } else {
            //新增

            // this.paramForm.label = this.paramForm.label.join(',')

            // console.log(this.paramForm.label)
            const { data: res } = await saveNameCard(this.paramForm)

            if (res.resultCode === 200) {
              this.$message.success('新增成功！')
              this.$router.go(-1)
            } else {
              this.$message.success('新增失败，请稍候重试')
            }
          }
        } else {
          this.$message.warning('请完善基本信息')
        }
      })
    },
    uploadOneImgShow(val) {
      this.paramForm.cover = val
    },
    updateImgOne(val) {
      this.paramForm.cover = val
    },
    //取消
    cancel() {},

    clickCancel() {
      this.$router.go(-1)
      // console.log('取消')
    },

    // 添加标签
    clickAddLabel() {
      if (this.addLebal.length == 0) {
        return this.$message('请输入要添加的标签内容')
      }

      // if (this.paramForm.label == null || this.paramForm.label == '') {
      //   this.paramForm.label = []
      // }
      this.paramForm.labels.push(this.addLebal)
      this.addLebal = ''
    },
    // 点击移除标签
    clickRemovLabel(idx) {
      this.paramForm.labels.forEach((item, index) => {
        if (index == idx) {
          this.paramForm.labels.splice(index, 1)
        }
      })
    },
    // 富文本输入事件
    async editorChange(val) {
      // console.log(1)

      let replace = val.replace(/<[^>]+>/g, '')
      const { data: res } = await selectParticulars({
        str: replace
      })
      // console.log(res)
      if (res.resultCode == 200 && res.data != null) {
        this.paramForm.keywords = res.data.join(',')
      } else {
        this.paramForm.keywords = ''
      }
    },
    // 关联会员
    changeSelect() {
      console.log(this.paramForm.userId)
    },

    // 关联会员数据获取
    async getListInformationAndCustomerResults() {
      const { data: res } = await selectCustomerList()
      if (res.resultCode == 200) {
        this.ListInformationAndCustomerResultsVal = [...res.data]
        if (this.type) {
          this.ListInformationAndCustomerResultsVal.push({
            id: this.paramForm.userId,
            userName: this.paramForm.userName
          })
        }
      }
    },
    // 关联会员选中的值
    selectChange(item, index) {}
  }
}
</script>
<style lang="less" scoped>
.informationList {
  width: 100%;

  .information-tip {
    font-size: 18px;
    color: #968de2;
  }

  .information-line {
    width: 100%;
    height: 0px;
    border: 1px solid #efefef;
    margin-top: 15px;
  }

  .information-name {
    margin-top: 10px;
  }

  .tag-add {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    color: #7067f2;
  }

  .tag-style {
    background-color: #f1f0fe;
    color: #7067f2;
    border-color: #ffffff;
  }
  .el-select {
    width: 450px;
    height: 40px;
    border-radius: 4px;
  }

  /deep/.w-e-text-container {
    z-index: 1 !important;
  }

  /deep/.w-e-menu {
    z-index: 2 !important;
  }

  /deep/.w-e-toolbar {
    z-index: 2 !important;
  }

  /deep/.el-button.is-plain:focus,
  .el-button.is-plain:hover {
    border-color: #7c71e1;
  }

  .editBar {
    width: 800px;
  }

  .title {
    margin-bottom: 0;
    .basicMsg {
      width: 100px;
      // display: flex;
      // justify-content: flex-end;
      font-size: 16px;
      color: #333;
      font-weight: 700;
      margin: 24px 0 0 0;
    }
    /deep/.el-form-item__content {
      margin-left: 35px !important;
      // line-height: 30px !important;
    }
  }

  .accessory {
    margin-top: 15px;
    width: 1024px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      .input {
        width: 450px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #dcdcdc;
        font-size: 14px;
        color: #333333;
        padding-left: 13px;
      }

      .upload {
        width: 72px;
        height: 33px;
        color: #7c71e1;
        background-color: rgba(112, 103, 242, 0.1);
        border-radius: 2px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 25px;
      }
    }
  }

  .contactVip {
    margin-top: 15px;
    width: 1024px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      .el-select {
        width: 450px;
        height: 40px;
        border-radius: 4px;
      }
      .add {
        margin-left: 20px;
        font-size: 17px;
        color: #7c71e1;
        cursor: pointer;
      }
    }
  }
}
.correlationLinks {
  margin-top: 15px;
  width: 1024px;
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    .linksBox {
      width: 864px;
      height: 242px;
      border: 1px dashed #dcdcdc;

      .link {
        margin-top: 15px;
        display: flex;
        .label {
          display: flex;
          justify-content: flex-end;
          width: 70px;
          font-size: 12px;
          color: #333333;
          padding-right: 7px;
          box-sizing: border-box;
        }
        .el-input {
          width: 450px;
          height: 40px;
        }
      }
      .linkName {
        display: flex;
        margin-top: 15px;
        .label {
          display: flex;
          justify-content: flex-end;
          width: 70px;
          font-size: 12px;
          color: #333333;
          padding-right: 7px;
          box-sizing: border-box;
        }
        .el-input {
          width: 450px;
          height: 40px;
        }
      }
      .linkDigest {
        display: flex;
        margin-top: 15px;
        .label {
          display: flex;
          justify-content: flex-end;
          width: 75px;
          font-size: 12px;
          color: #333333;
          padding-right: 7px;
          box-sizing: border-box;
        }
        /deep/.el-textarea__inner {
          width: 762px;
          height: 85px;
        }
      }
    }

    .add {
      margin-left: 20px;
      font-size: 17px;
      color: #7c71e1;
      cursor: pointer;
    }
  }
  .right {
    line-height: 0px;
    .lineHeight {
      line-height: 0px;
    }
  }
}
.add_label {
  width: 126px;
  height: 40px;
  border-radius: 4px;
}
.add_icon {
  margin: 0 6px 0 14px;
}
.color {
  color: #7067f2;
  cursor: pointer;
}
.add_box {
  display: inline-block;
  cursor: pointer;
}
.addAccessory {
  color: #7067f2;
  cursor: pointer;
  font-size: 17px;
  margin-left: 14px;
}
.lebel_item {
  background-color: rgba(112, 103, 242, 0.1);
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  display: inline-block;
  font-size: 14px;
  color: #7067f2;
  border-radius: 2px;
  margin-right: 9px;
}
.remove_icon {
  cursor: pointer;
}
.remove_label {
  display: flex;
  align-items: center;
}
.keyword {
  width: 800px;
  height: 184px;
}
/deep/.el-textarea__inner {
  width: 868px;
  height: 184px;
}
.delete {
  color: #fd523f;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
}
.bottomButton {
  padding-left: 100px;
}
</style>
